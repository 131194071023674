import React, { useState, useContext, useEffect, useMemo } from 'react'
import MarkdownRenderer from '../../../../Components/MarkdownRenderer'
import { Box, Grid, Button, Stack, Card, CardContent, Typography, TextField, Divider, LinearProgress, CircularProgress } from '@mui/material'
import { Send } from '@mui/icons-material'
import { formatDate } from '../../../../utils/formatDate'
import { NOTE_TYPE } from './types'

import ApiContext from '../../../../Services/apiService'

const NOTE_TYPE_COLORS = {
  [NOTE_TYPE.PENDING]: '',
  [NOTE_TYPE.ASSIGNED]: 'primary',
  [NOTE_TYPE.ON_HOLD]: 'warning',
  [NOTE_TYPE.COMPLETED_POSITIVE]: 'success',
  [NOTE_TYPE.COMPLETED_NEGATIVE]: 'error',
  [NOTE_TYPE.CANCELLED]: 'error',
  [NOTE_TYPE.CLAIMS_AMENDED]: 'success',
  [NOTE_TYPE.USER_COMMENT]: 'grey',
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const RenderNote = ({ note }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={9}>
        <Typography fontWeight='bold' component='span'>{note.user?.email}</Typography> <Typography fontWeight='bold' component='span' color={NOTE_TYPE_COLORS[note.noteType]}>{note.noteTitle}</Typography>
      </Grid>
      <Grid item xs={3} textAlign='end'>
        <Typography variant='caption'>
          {formatDate({ date: note.createdAt })}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <MarkdownRenderer markdownText={note.noteText} />
      </Grid>
    </Grid>
  )
}

const NotesTab = ({ taskId }) => {
  const [noteText, setNoteText] = useState('')
  const [notes, setNotes] = useState([])
  const [loadingNotes, setLoadingNotes] = useState(false)
  const [postingNote, setPostingNote] = useState(false)

  const api = useContext(ApiContext)

  const fetchNotes = async () => {
    setLoadingNotes(true)
    const result = await api.getTaskNotes(taskId)
    console.log('got notes', result)
    setNotes(result)
    setLoadingNotes(false)
  }

  useEffect(async () => {
    await fetchNotes()
  }, [taskId, api])

  const postNote = useMemo(() => async (text) => {
    setPostingNote(true)
    const newNote = await api.submitTaskNote({
      taskId,
      noteText: text,
    })
    setLoadingNotes(true)
    setNotes([
      newNote,
      ...notes,
    ])
    setNoteText('')
    setPostingNote(false)
    // This time is a hack to give the event time to propagate
    await sleep(5000)
    fetchNotes()
  }, [notes, taskId, api])

  const onChange = (event) => {
    setNoteText(event?.target.value)
  }

  return (
    <>
      <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Typography variant='h5'>
            Add notes
          </Typography>
          <Stack direction='row' spacing={1}>
            <TextField fullWidth multiline rows={4} value={noteText} onChange={onChange} />
            <Box>
              {postingNote && <CircularProgress />}
              {!postingNote && <Button startIcon={<Send />} onClick={() => postNote(noteText)}>
                Send
              </Button>}
            </Box>

          </Stack>

        </CardContent>
      </Card>
        <Card sx={{ p: 1, m: 1 }}>
          <CardContent>
            <Stack spacing={1} justifyContent='space-between' direction='row' paddingBottom={!loadingNotes ? '0.25rem' : '0rem'}>
              <Typography variant='h5'>
                Activity log
              </Typography>
              <Button onClick={fetchNotes}>Refresh</Button>
            </Stack>
            {loadingNotes && <LinearProgress />}
            {notes.length > 0 && (<Stack spacing={1} divider={<Divider />}>
              {notes.map((note) => <RenderNote key={`note-${note.noteId}`} note={note} />)}
            </Stack>)}
          </CardContent>
        </Card>

    </>
  )
}

export default NotesTab
