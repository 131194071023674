import { useState, useEffect } from 'react'
import { Container, Box, Typography, Card, CardContent, CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { formatDate } from '../../utils/formatDate'
import { useParams } from 'react-router-dom'
import LicenseRecord from './records/LicenseRecord'

const FAKE_DATA = {
  recordType: 'LICENSE',
  versions: [
    1683827636000,
    1683826636000,
  ],
  selectedVersion: 1683827636000,
  version: 5,
  licenseRegion: 'CA',
  issuingAuthority: 'California License Board',
  licenseIdentifier: '123454321',
  licenseType: 'homeServices',
  licenseTypeAsIs: 'Plumber',
  licenseStatus: 'Active',
  licenseStatusAsIs: 'Current, Active',
  expirationDate: 1683827636000,
  issueDate: 1683526636000,
  licenseeName: 'George Record',
}
const RECORD_TYPES = {
  LICENSE: 'LICENSE',
}

const RECORD_TYPE_MAP = {
  [RECORD_TYPES.LICENSE]: LicenseRecord,
}

const RecordData = ({ isLoading, data, selectedVersion, setSelectedVersion }) => {
  if (isLoading) {
    return <CircularProgress />
  }
  if (RECORD_TYPE_MAP[data.recordType]) {
    return RECORD_TYPE_MAP[data.recordType]({ data, selectedVersion, setSelectedVersion })
  }
  return (
    <Typography>No mapping found for {data.recordType}</Typography>
  )
}

const VersionDropdown = ({ selectedVersion, setSelectedVersion, versions = [] }) => {
  const handleChange = (event) => {
    setSelectedVersion(event.target.value)
  }
  console.log('versions', selectedVersion, versions)
  if (versions.length <= 1) {
    return null
  }
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-helper-label">Version</InputLabel>
      <Select
        labelId="demo-simple-select-helper-label"
        id="version"
        value={selectedVersion}
        label="Record Version"
        onChange={handleChange}
      >
        <MenuItem key='latest-version' value={-1}>Latest - {versions.length} versions available</MenuItem>
        {versions.map((version) => <MenuItem key={version} value={version}>{formatDate({ date: version })}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

const RecordViewer = () => {
  const { recordId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState({})
  // version is stored as an epoch time. We use -1 as a synonym for "latest"
  const [selectedVersion, setSelectedVersion] = useState(-1)
  useEffect(async () => {
    setIsLoading(true)

    setTimeout(() => {
      setData(FAKE_DATA)
      setIsLoading(false)
    }, 200)
  }, [recordId, selectedVersion])
  return (
    <Container maxWidth='xl'>
      <Card sx={{ p: 1, m: 1 }}>
        <CardContent>
          <Typography variant="h3">
            Record {recordId} <VersionDropdown selectedVersion={selectedVersion} setSelectedVersion={setSelectedVersion} versions={data.versions} />
          </Typography>
          <Box>

            <RecordData isLoading={isLoading} data={data} selectedVersion={selectedVersion} setSelectedVersion={setSelectedVersion} />
          </Box>
        </CardContent>
      </Card>

    </Container>

  )
}

export default RecordViewer
