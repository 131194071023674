import './App.css'
import { Authenticator, useTheme, View } from '@aws-amplify/ui-react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import Amplify from 'aws-amplify'
import awsconfig from '../../aws-exports'
import TaskQueue from '../TaskQueue'
import TaskHandler from '../TaskHandler'
import Logo from '../../Components/Logo/Logo'
import RecordSelector from '../RecordSelector'
import RecordViewer from '../RecordViewer'
import Layout from '../Layout'
import Documentation from '../Documentation'
import ListTools from '../Tools/ListTools'

Amplify.configure(awsconfig)

const components = {
  Header () {
    const { tokens } = useTheme()
    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Logo />
      </View>
    )
  },
}

export function App () {
  return (
    <Authenticator hideSignUp={true} components={components}>
      <BrowserRouter>
        <div className="App" data-testid="app" >
          <Routes>
            <Route path='*' element={<Navigate to='/home'/>} />
            <Route path="/" element={<Layout />}>
              <Route path="/home" element={<TaskQueue/>}>
                <Route path=":taskId" element={<TaskHandler/>}/>
              </Route>
              <Route path="/taskHandler" element={<TaskQueue/>}>
                <Route path=":taskId" element={<TaskHandler/>}/>
              </Route>
              <Route path="/record">
                <Route index element={<RecordSelector/>}/>
                <Route path=":recordId" element={<RecordViewer/>}/>
              </Route>
              <Route index path="/docs/*" element={<Documentation />}/>
              <Route index path="/tools/*" element={<ListTools />}/>
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </Authenticator>
  )
}

export default App
